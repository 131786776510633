<template>
  <b-row>
    <b-col cols="12">
      <b-overlay
        :show="$apollo.loading"
        spinner-variant="primary"
      >
        <b-card title="All Startups">
          <!-- search input -->
          <div class="custom-search d-flex justify-content-between">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input
                  v-model="searchTerm"
                  class="d-inline-block mr-1"
                  placeholder="Search"
                  type="text"
                />
              </div>
            </b-form-group>

          </div>

          <!-- table -->
          <vue-good-table
            :columns="columns"
            :pagination-options="{
              enabled: true,
              perPage:pageLength,
              position: 'top',
            }"
            :rows="startups"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: URL -->
              <span
                v-if="props.column.field === 'title'"
                class="text-nowrap"
              >
                <b-link
                  :href="prependHttps(props.row.url)"
                  target="_window"
                >{{ props.formattedRow[props.column.field] }}</b-link>
              </span>

              <!-- <span
                v-else-if="props.column.field === 'url'"
                class="text-nowrap"
              >
                <b-link
                  :href="prependHttps(props.row.url)"
                  target="_window"
                >{{ props.row.url }}</b-link>
              </span> -->

              <!-- Column: Status -->

              <span v-else-if="props.column.field === 'incubator_name'">
                {{ selectedOrg.title }}
              </span>

              <!-- Column: Button -->
              <span v-else-if="props.column.field === 'buttons'">
                <b-button
                  class="mr-1"
                  variant="outline-primary"
                  size="sm"
                  :to="{name: 'startup-agreements', params: {id: props.row.id}}"
                >Agreements</b-button>
                <b-button
                  class="mr-1"
                  variant="outline-primary"
                  size="sm"
                  :to="{name: 'startups-manager', params: {id: props.row.id}}"
                >Manager</b-button>
                <b-button
                  variant="outline-primary"
                  size="sm"
                  :to="{name: 'startup-performance', params: {id: props.row.id}}"
                >Performance</b-button>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
            <!-- pagination -->
            <template
              slot="pagination-top"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mb-1">
                  <span class="text-nowrap ">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of <span class="font-medium-4 ml-25"><b-badge
                    variant="light-primary"
                  >{{
                    props.total
                  }} entries
                  </b-badge></span></span>

                </div>
                <div>
                  <b-pagination
                    :per-page="pageLength"
                    :total-rows="props.total"
                    :value="1"
                    align="right"
                    class="mt-50"
                    first-number
                    last-number
                    next-class="next-item"
                    prev-class="prev-item"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-overlay>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBadge, BButton, BCard, BFormGroup, BFormInput, BFormSelect, BLink, BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import gql from 'graphql-tag'
// import { mapGetters } from 'vuex'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BLink,
    VueGoodTable,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
  },
  data() {
    return {
      pageLength: 5,
      dir: false,
      filter: null,
      columns: [
        {
          label: 'Startup Name',
          field(row) {
            return row?.title
          },
        },
        {
          label: 'Type',
          field: 'type',
          filterOptions: {
            enabled: true, // enable filter for this column
            customFilter: true, // use custom filter component
            placeholder: '-- No Filter --', // placeholder for filter input
            filterDropdownItems: [
              {
                value: 'pre-incubatee',
                text: 'Pre-Incubatee',
              }, {
                value: 'incubatee',
                text: 'Incubatee',
              }, {
                value: 'graduate',
                text: 'Graduate',
              }, {
                value: 'startup',
                text: 'Startup',
              }],
          },
        },
        {
          label: 'Incubator Name',
          field: 'incubator_name',
        },
        {
          label: '',
          field: 'buttons',
          sortable: false,
        },
      ],
      startups: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  computed: {
    ...mapGetters({
      selectedOrg: 'user/getSelectedOrgDetails',
    }),
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  apollo: {
    startups: {
      query: gql`
      query startups($incubator_id: Int!) {
      users_associationtable(where: {role: {_in: ["pre-incubatee","incubatee", "graduate"]}, incubator_id: {_eq: $incubator_id}}) {
       incubator_id
            id
            role
            users_organizationtable {
             title
             domain
            }
       }
     }`,
      variables() {
        return {
          incubator_id: this.selectedOrg.orgId,
        }
      },
      update(data) {
        const result = []
        data.users_associationtable.forEach(association => {
          result.push({
            type: association.role,
            ...association.users_organizationtable,
          })
        })
        return result
      },
    },
  },
}
</script>
